import React from 'react';
import { Button } from '../components/ui/Button';

const AboutPage = () => {
   return (
       <main className="flex-grow container mx-auto px-4 py-16 sm:py-24 bg-[#252526]">
           <section className="max-w-3xl w-full">
               <h1 className="text-2xl sm:text-4xl font-bold mb-8 tracking-tight leading-tight">
                   <span className="text-[#569CD6]">class</span>
                   <span className="text-[#9CDCFE]"> Maincode</span>
                   <span className="text-[#D4D4D4]"> {"{"}</span>
               </h1>

               {/* Vision Section */}
               <div id="vision" className="mb-8 sm:mb-12">
                   <div className="text-[#6A9955] text-sm sm:text-base mb-2">{"// Our Vision"}</div>
                   <div className="text-[#D4D4D4] text-sm sm:text-base pl-4 space-y-4">
                       <p>
                           Maincode is the antidote to everything wrong with modern engineering culture. The joy of building, the pride of ownership, the thrill of tackling tough problems - it's all been lost.
                       </p>
                       <p>
                           We're starting a movement to restore the heart and soul of engineering. We're stripping away the layers of bureaucracy to rebuild our culture from the ground up.
                       </p>
                       <p>
                           We're creating a place where engineers own their destiny, culture, and code; where we choose hard, groundbreaking projects that delight users and push boundaries.
                       </p>
                       <p>
                           We know great software comes from small, passionate teams with a mission and love for their craft. It comes from autonomy, risk-taking, and the drive to be the best. That's the environment we're fostering.
                       </p>
                       <p>
                           No more top-down mandates or death marches, just exceptional engineers building something extraordinary together.
                       </p>
                       <p>
                           Our DNA is rooted in the belief that great engineering thrives in adversity and innovates in scarcity. We draw inspiration from the giants before us who understood that true breakthroughs come from venturing into uncharted waters.
                       </p>
                       <p>
                           We carry their spirit of relentless curiosity, unwavering commitment to excellence, and deep love for the craft.
                       </p>
                       <p>
                           Growth comes from tackling hard problems; knowledge is forged in the crucible of risk and challenge. Nothing of enduring value comes easy - and that's what drives us.
                       </p>
                       <p>
                           We're here to push boundaries, challenge the status quo, and build the future we know is possible. World-changing innovation happens when passionate, dedicated engineers have the freedom and support to do what they do best.
                       </p>
                       <p>
                           By getting the fundamentals right and fostering a culture of excellence and continuous improvement, we set ourselves up for success no matter what the future holds. We're not interested in quick fixes; we're here to build systems and software that stand the test of time and evolve with our users' needs.
                       </p>
                       <p>
                           We take pride in our work, sweat the details, and care deeply about the code and the customer.
                       </p>
                       <p>
                           As engineers and people, we may only get one chance in our lives to do something truly remarkable. One chance to be part of something bigger than ourselves, to create something that will outlast us. To be part of a team that has each other's backs, lifts each other up, and is more than the sum of its individual talents.
                       </p>
                       <p>
                           In software engineering, the life of code starts with main: threads, loops, modules, memory, branches, functions. For us, it's the same. Our code is not just what we write; it defines who we are.
                       </p>
                       <p>
                           Everything we do is the start of something important. Each line of intention, each moment of creation, each decision of excellence, is a declaration that we are not just writing software, but building the future, one committed line at a time.
                       </p>
                   </div>
               </div>

               {/* Mission Section */}
               <div className="mb-8 sm:mb-12">
                   <div className="text-[#6A9955] text-sm sm:text-base mb-2">{"// About Us"}</div>
                   <p className="text-[#D4D4D4] text-sm sm:text-base pl-4">
                       At Maincode, we're on a mission to restore the heart and soul of engineering. We're creating a place where engineers own their work, their culture, and their code, where passion, grit, and craftsmanship come together to build extraordinary products. We reject bureaucracy, embrace hard problems, and believe greatness is earned.
                   </p>
               </div>

               {/* Values Section */}
               <div className="mb-8 sm:mb-12">
                   <div className="text-[#6A9955] text-sm sm:text-base mb-2">{"// Our Culture"}</div>
                   <p className="text-[#D4D4D4] text-sm sm:text-base pl-4">
                       Our culture is built around bringing together humble, curious, and passionate engineers and giving them the support, tools, and environment to develop incredible skills and products. Engineering excellence is at the core of our DNA, along with a commitment to continuous learning and growth. We focus on relentless performance and uncompromising standards. Bureaucracy, red tape, and bar-lowering thinking are refactored out to allow hardcore engineering to thrive and push the boundaries of what's possible. Here, engineers can truly be engineers, tackling hard problems and building extraordinary things together.
                   </p>
               </div>

               {/* Join Us Section */}
               <div className="mb-8 sm:mb-12">
                   <div className="text-[#6A9955] text-sm sm:text-base mb-2">{"// Join Our Team"}</div>

                   <Button
                       variant="eng"
                       onClick={() => window.open('https://app.dover.com/jobs/maincode', '_blank')}
                       className="text-base sm:text-lg py-2 sm:py-3"
                   >
                       View Open Positions →
                   </Button>
               </div>

               <div className="text-[#D4D4D4] text-2xl sm:text-4xl">{"}"}</div>
           </section>
       </main>
   );
};

export default AboutPage;